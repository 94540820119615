import { useEffect } from 'react';
import { captureAppError } from '@leagueplatform/observability';
import { fetchDataWithToken } from 'utils/fetchDataWithToken';
import { getAbsoluteRootUrl, shouldIntercept } from 'utils/helpers';

export function useInterceptLinkClick(
  pathToIntercept: string,
  rootUrl: string,
): void {
  useEffect(() => {
    const handleClick = async (event: MouseEvent) => {
      let element = event.target as HTMLElement;

      while (element && element.tagName !== 'A' && element.parentElement) {
        element = element.parentElement;
      }

      if (element.tagName === 'A') {
        const href = element.getAttribute('href')!;
        try {
          const absoluteHref = new URL(href, rootUrl).href;
          if (shouldIntercept(element, pathToIntercept)) {
            event.preventDefault();
            event.stopPropagation();

            const absoluteRootUrl = getAbsoluteRootUrl(rootUrl);
            const modifiedUrl = new URL(href, absoluteRootUrl).href;

            // Open a new tab immediately with a loading placeholder
            const newTab = window.open('', '_blank');
            if (newTab) {
              newTab.document.write(
                '<html><head><title>Loading...</title></head><body></body></html>',
              );
              newTab.document.close();
            }

            fetchDataWithToken(modifiedUrl)
              .then((htmlContent) => {
                if (newTab) {
                  newTab.document.open();
                  newTab.document.write(htmlContent);
                  newTab.document.close();
                  newTab.onload = () => {
                    const form = newTab.document.forms[0];
                    if (form) {
                      form.submit();
                    }
                  };
                }
              })
              .catch((error) => {
                captureAppError(error);
                if (newTab) {
                  newTab.document.open();
                  newTab.document.write(
                    '<html><head><title>Error</title></head><body><h4>Error loading the content.</h4></body></html>',
                  );
                  newTab.document.close();
                }
              });
          } else if (absoluteHref.includes('open-link?')) {
            event.preventDefault();
            event.stopPropagation();

            const urlParams = new URLSearchParams(new URL(absoluteHref).search);
            const webParam = urlParams.get('web');
            if (webParam) {
              const decodedWebUrl = decodeURIComponent(webParam);
              const cleanedWebUrl = decodedWebUrl.replace(
                /[^a-zA-Z0-9/:.]/g,
                '',
              );

              window.open(cleanedWebUrl, '_blank');
            }
          }
        } catch (error: any) {
          captureAppError(error);
        }
      }
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('auxclick', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('auxclick', handleClick);
    };
  }, [pathToIntercept, rootUrl]);
}
