import { useEffect } from 'react';
import { LeagueConfig, setConfig } from '@leagueplatform/core';
import { FEATURE_FLAGS } from 'common/firebase';
import { useFeatureFlags } from './use-feature-flags.hook';

// Extract the available Journey tabs from the config schema.
type JourneyTabs = Pick<
  // Exclude the undefined value so that we can pick the tabs later.
  Exclude<LeagueConfig['healthJourney'], undefined>,
  'tabs'
>['tabs'];

export const useConfigWithFeatureFlags = (): { isLoading: boolean } => {
  const { featureFlags, isLoading: featureFlagsAreLoading } = useFeatureFlags([
    FEATURE_FLAGS.HEALTH_JOURNEY_TABS,
  ]);

  useEffect(() => {
    if (featureFlags) {
      setConfig((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.healthJourney = featureFlags[
          FEATURE_FLAGS.HEALTH_JOURNEY_TABS
        ] as { tabs?: JourneyTabs };
      });
    }
  }, [featureFlags]);

  return { isLoading: featureFlagsAreLoading };
};
