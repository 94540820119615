import { captureAppError } from '@leagueplatform/observability';
import { Auth } from '@leagueplatform/auth';
import { SENTRY_CONSTANTS } from 'common/constants';

const USER_API = '/v1/users';

export async function deleteUserAccount(userId: string): Promise<void> {
  const token = await Auth.getToken();

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/${USER_API}/${userId}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!response.ok) {
    captureAppError(new Error('Failed to delete users account'), {
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
    });
    throw new Error('Failed to delete account');
  }
}
