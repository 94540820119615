import { useCallback, useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { useLocalStorage } from 'hooks/use-local-storage';
import { OnboardingFeatureHighlights } from 'components/onboarding/feature-highlights.component';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { AllSetPage } from './all-set.component';

export const OnboardingWrapper = ({ children }: { children: JSX.Element }) => {
  const [featureHighlightCompleted, setFeatureHighlightCompleted] =
    useLocalStorage('featureHighlightCompleted', false);
  const [showFeatureHighlights, setShowFeatureHighlights] = useState(false);
  const [showAllSetPage, setShowAllSetPage] = useState(false);

  const { data: userData, isLoading: isLoadingUserProfile } =
    useGetUserProfile();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFeatureHighlights(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleFeatureHighlightCompleted = useCallback(() => {
    setShowAllSetPage(true);
  }, []);

  const handleOnboardingCompleted = useCallback(() => {
    setFeatureHighlightCompleted(true);
  }, [setFeatureHighlightCompleted]);

  // Avoid showing the onboarding flow if the account is older than one day.
  if (userData?.joinedTimestamp) {
    const daysDelta = differenceInDays(Date.now(), userData.joinedTimestamp);
    if (daysDelta > 0) {
      return children;
    }
  }

  if (
    (!featureHighlightCompleted && !showFeatureHighlights) ||
    isLoadingUserProfile
  ) {
    return <FullPageLoadingSpinner />;
  }

  if (!featureHighlightCompleted && showFeatureHighlights && !showAllSetPage) {
    return (
      <OnboardingFeatureHighlights
        onOnboardingComplete={handleFeatureHighlightCompleted}
      />
    );
  }

  if (!featureHighlightCompleted && showAllSetPage) {
    return <AllSetPage handleOnboardingCompleted={handleOnboardingCompleted} />;
  }

  return children;
};
