import * as React from 'react';
import { LoadingSpinner } from 'components/loaders/loading-spinner.component';
import { logout } from 'utils/auth/logout';

export const AuthSignOut = () => {
  React.useEffect(() => {
    logout();
  }, []);

  return <LoadingSpinner />;
};
