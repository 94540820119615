import {
  Icon,
  Box,
  TextAction,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const FormCenterCard = ({
  title,
  linkUrl,
  icon,
}: {
  title: string;
  linkUrl: string;
  icon: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <TextAction
      href={linkUrl}
      target="_blank"
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        textAlign: 'left',
        fontSize: '$bodyTwo',
        fontWeight: 'normal',
        color: '$onSurfaceTextSubdued',
        border: '1px solid #E0E3E6',
        borderRadius: '9px',
        '&:hover': {
          textDecoration: 'none',
        },
      }}
    >
      <Box
        css={{
          flex: 1,
          typography: '$subtitleOne',
          marginTop: '$half',
          paddingRight: '$two',
        }}
      >
        {title}
      </Box>
      {linkUrl && (
        <>
          <Icon
            icon={
              icon === 'chevron'
                ? 'interfaceChevronRight'
                : 'interfaceExternalLink'
            }
            size="$one"
            tint="$onSurfaceTextSubdued"
          />
          <VisuallyHidden data-testid="new-tab-msg">
            {formatMessage({ id: 'OPENS_IN_A_NEW_TAB' })}
          </VisuallyHidden>
        </>
      )}
    </TextAction>
  );
};
