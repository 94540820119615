import { useQuery, UseQueryOptions } from 'react-query';
import {
  GET_APP_INIT_DETAILS_MESSAGE_TYPE,
  getAppInitDetails,
  AppInitDetails,
} from '../api/get-app-init-details';

const QUERY_CACHE_AND_STALE_TIME = 15 * 1000 * 60;

export const useGetAppInitDetails = <
  TSelect extends AppInitDetails = AppInitDetails,
>(
  options?: UseQueryOptions<AppInitDetails, Error, TSelect>,
) => {
  const { data } = useQuery<AppInitDetails, Error, TSelect>(
    [GET_APP_INIT_DETAILS_MESSAGE_TYPE],
    getAppInitDetails,
    {
      staleTime: QUERY_CACHE_AND_STALE_TIME,
      cacheTime: QUERY_CACHE_AND_STALE_TIME,
      ...options,
    },
  );

  return {
    data,
  };
};

export type BrandingInfo = {
  brandingLogo: string;
  brandingMessage: string;
  brandingName: string;
};
