import { useMutation } from 'react-query';
import { deleteUserAccount } from 'api/delete-user-account';

type UseDeleteAccountParams = { onDeleted: () => void };

export function useDeleteAccount({ onDeleted }: UseDeleteAccountParams) {
  const mutation = useMutation(deleteUserAccount, {
    onSuccess() {
      onDeleted();
    },
  });
  return mutation;
}
