import { getMasonryWSDriver } from '@leagueplatform/masonry';
import { useIntl } from '@leagueplatform/locales';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { HeroSection } from 'components/hero-section/hero-section.component';
import {
  styled,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';

const MasonryWSDriver = getMasonryWSDriver({
  appId: 'cigna_form_center',
  screenName: 'form-center',
  productArea: 'cigna_form_center',
  apiParams: {},
});

/**
 * TECH DEBT: This is a hack that forces the flat list of items into a grid layout.
 * This code is fragile and only intended as a temporary fix until we properly
 * implement the grid using the masonry grid renderer.
 */
const MasonryGrid = styled('div', {
  // Masonry wraps each renderer in its own div.  We need to traverse down the tree
  // until we get the element we need.
  '& > div > div': {
    display: 'grid',
    '@initial': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    '@desktop': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    '@laptop': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    '@tablet': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@mobileLandscape': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@mobile': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  '& :has(> h3)': {
    // Make the headers span the full grid width
    gridColumn: '1/-1',
  },
});

const FormsCenterLandingPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'FORMS_CENTER' }));

  return (
    <MainPageContainer width="100%">
      <HeroSection title={formatMessage({ id: 'FORMS_CENTER' })} />
      <StackLayout
        css={{
          maxWidth: 1048,
        }}
      >
        <ParagraphText emphasis="subtle">
          {formatMessage({ id: 'FORMS_CENTER_SUBTITLE' })}
        </ParagraphText>
        <MasonryGrid
          css={{
            paddingTop: '$three',
            paddingBottom: '$five',
            width: '100%',
          }}
        >
          <MasonryEngine driver={MasonryWSDriver} />
        </MasonryGrid>
      </StackLayout>
    </MainPageContainer>
  );
};

export default FormsCenterLandingPage;
