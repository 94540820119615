import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { Auth } from '@leagueplatform/auth';

// Extract the auth clients logout options and extend it by adding the accessToken.
type LogoutOptions = Parameters<typeof StandaloneAuth.client.logout>[0] & {
  accessToken?: string;
};

export function logout() {
  Auth.getToken().then((token) => {
    const logoutOptions: LogoutOptions = {
      federated: true,
      accessToken: token,
      returnTo: window.location.origin,
    };
    StandaloneAuth.client.logout(logoutOptions);
  });
}
