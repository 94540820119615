import { Suspense } from 'react';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  FullPageError,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import League, { LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { config } from 'init-config';
import { ErrorBoundaryWrapper } from 'components/error/error-boundary.component';
import { FormCenterCard } from 'components/form-center/form-center-card.component';
import { APP_MANAGED_ROUTES } from 'common/constants';
import AppRoutes from './components/AppRoutes';

League.overwriteRoutePaths({
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
});

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'cigna',
  'cigna_form_center_item',
  FormCenterCard,
);

export function App() {
  return (
    <League.LeagueProvider config={config} treatConfigAsInitialOnly>
      <ErrorBoundaryWrapper>
        <Suspense fallback={<LoadingIndicator />}>
          {useOnlineStatus().isOffline && (
            <FullPageError route={APP_MANAGED_ROUTES.HOME} />
          )}
          {useOnlineStatus().isOnline && (
            <ErrorBoundaryWrapper>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </ErrorBoundaryWrapper>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundaryWrapper>
    </League.LeagueProvider>
  );
}
