import {
  StackLayout,
  HeadingText,
  ParagraphText,
  useMediaQuery,
  queryHelpers,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { FullPageError } from 'components/error/full-page-error.component';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import { LoadingSpinner } from 'components/loaders/loading-spinner.component';
import { useDeleteAccount } from 'hooks/use-delete-account';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import exitWarning from 'assets/exit-warning.svg';
import { EXTERNAL_LINK } from 'common/constants';
import { ModalActionButton } from './common/modal-action-button.component';

type DeleteAccountModalProps = {
  onDeleted: () => void;
};

export const DeleteAccountModal = ({ onDeleted }: DeleteAccountModalProps) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));
  const deleteAccountMutation = useDeleteAccount({
    onDeleted,
  });
  const userProfileQuery = useGetUserProfile();

  if (userProfileQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (!userProfileQuery.isSuccess || deleteAccountMutation.isError) {
    return (
      <FullPageError
        errorType={ErrorTypes.API_ERROR}
        buttonLink={EXTERNAL_LINK.MY_CIGNA}
        buttonChildren={formatMessage({ id: 'GO_TO_MY_CIGNA' })}
      />
    );
  }
  const { userId } = userProfileQuery.data;

  return (
    <BaseModalContainer
      title={formatMessage({ id: 'DELETE_YOUR_ACCOUNT' })}
      footer={
        <StackLayout
          horizontalAlignment="end"
          orientation={{
            '@initial': 'horizontal',
            '@mobile': 'vertical',
          }}
          spacing="$one"
          css={{ flexGrow: 0 }}
        >
          <ModalActionButton
            actionType="cancel"
            labelId="NEVER_MIND"
            isMobile={isMobile}
            cssStyle={{ color: '$secondaryTextDefault' }}
          />
          <ModalActionButton
            actionType="submit"
            onClick={() => {
              deleteAccountMutation.mutate(userId);
            }}
            labelId="YES_DELETE_ACCOUNT"
            isMobile={isMobile}
            isDestructive
          />
        </StackLayout>
      }
    >
      <ImageMessage
        image={exitWarning}
        css={{
          flexGrow: 1,
          padding: '$two $half $five $half',
          textAlign: 'left',
          '@mobile': { textAlign: 'center' },
        }}
      >
        <HeadingText
          level="2"
          size={{
            '@initial': 'lg',
            '@mobile': 'xl',
          }}
          css={{ paddingBlock: '$one', fontWeight: 'bold' }}
        >
          {formatMessage({ id: 'ARE_YOU_SURE' })}
        </HeadingText>
        <ParagraphText>
          {formatMessage({ id: 'DELETE_ACCOUNT_BODY_1' })}
        </ParagraphText>
        <ParagraphText css={{ marginBlock: '$one' }}>
          {formatMessage({ id: 'DELETE_ACCOUNT_BODY_2' })}
        </ParagraphText>
      </ImageMessage>
    </BaseModalContainer>
  );
};
