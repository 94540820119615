import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';
import {
  Options,
  useUserDropdownConfig,
} from 'hooks/use-user-dropdown-config.hook';

function useFilterUserDropdownConfig(
  config: Options[],
  isMobile: boolean,
): Options[] {
  const { formatMessage } = useIntl();

  if (isMobile) return config;

  return config
    .map((section) => ({
      ...section,
      items: section.items.filter((item) =>
        [
          formatMessage({ id: 'PERSONAL_INFO' }),
          formatMessage({ id: 'MANAGE_PHARMACY_SETTINGS' }),
          formatMessage({ id: 'FORMS_CENTER' }),
          formatMessage({ id: 'ABOUT_CIGNA_HEALTHCARE' }),
          formatMessage({ id: 'DELETE_YOUR_ACCOUNT' }),
          formatMessage({ id: 'LOG_OUT' }),
        ].includes(item.text),
      ),
    }))
    .filter((section) => section.items.length > 0);
}

export const useUserDropdownComponent = (handleDeleteAccount: () => void) => {
  const { data, isLoading, error } = usePreferredFirstName();
  const userDropdownConfig = useUserDropdownConfig(handleDeleteAccount);
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  const filteredConfig = useFilterUserDropdownConfig(
    userDropdownConfig,
    isMobile,
  );

  const dropdownMenuItemProps = {
    preferredFirstName: data.preferredFirstName,
    menuItemsConfig: filteredConfig,
  };

  return { data: dropdownMenuItemProps, isLoading, error };
};
