import { GDSIconProps, GDSStyleObject } from '@leagueplatform/genesis-core';
import { EXTERNAL_LINK } from 'common/constants';

export interface FooterLinkProps {
  text: string;
  url?: string;
  linkProps?: GDSStyleObject;
  icon?: GDSIconProps['icon'];
  target?: string;
}

const boldTextLink: GDSStyleObject = {
  fontWeight: 'bold',
};

export const footerLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'LANGUAGE_ASSISTANCE',
    url: EXTERNAL_LINK.LANGUAGE_ASSISTANCE,
    target: '_blank',
    linkProps: boldTextLink,
    icon: 'interfaceExternalLink',
  },
  {
    text: 'TERMS_OF_USE',
    url: EXTERNAL_LINK.TERMS_OF_USE,
    target: '_blank',
    linkProps: boldTextLink,
    icon: 'interfaceExternalLink',
  },
];
export const footerSubLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'PRIVACY_POLICY',
    url: EXTERNAL_LINK.PRIVACY_POLICY,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'PRIVACY_NOTICES',
    url: EXTERNAL_LINK.PRIVACY_NOTICES,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'PRIVACY_FORMS',
    url: EXTERNAL_LINK.PRIVACY_FORMS,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'HIPPA_NOTICE_OF_PRIVACY_PRACTICES',
    url: EXTERNAL_LINK.HIPPA_NOTICE_OF_PRIVACY_PRACTICES,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'GRAMM_LEACH_BILLEY_ACT_NOTICE',
    url: EXTERNAL_LINK.GRAMM_LEACH_BILLEY_ACT_NOTICE,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'NONDISCRIMINATION_NOTICE',
    url: EXTERNAL_LINK.NONDISCRIMINATION_NOTICE,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'APPEALS',
    url: EXTERNAL_LINK.APPEALS,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'GRIEVANCES',
    url: EXTERNAL_LINK.GRIEVANCES,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
  {
    text: 'DISCLAIMERS',
    url: EXTERNAL_LINK.DISCLAIMERS,
    target: '_blank',
    icon: 'interfaceExternalLink',
  },
];
